@font-face {
  font-family: Poppins-Bold;
  src: url(./fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url(./fonts/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: Poppins-Medium;
  src: url(./fonts/Poppins-Medium.ttf);
}

@font-face {
  font-family: Poppins-Regular;
  src: url(./fonts/Poppins-Regular.ttf);
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background-color: #ebebffd2;
}

:root {
  --primary-color: #6070ff;
  --neutrals-color: #172b4d;
}

* {
  scroll-behavior: smooth;
  box-sizing: border-box;
  color: var(--neutrals-color);
  list-style: none;
  text-decoration: none;
  font-family: Poppins-Regular, Roboto, sans-serif;
  margin: 0;
  padding: 0;
}

body {
  background-color: rgb(247, 247, 249);
}

:focus {
  outline-color: lightgreen;
}

::selection {
  background-color: rgb(173, 216, 230);
}

u {
  text-decoration: underline;
}

code {
  font-size: 24px;
  line-height: 36px;
}

span {
  color: inherit;
  font-size: inherit;
}

.menu-icon-btn {
  position: fixed;
  top: 1rem;
  right: 1rem;
  outline: none;
  border: none;
  padding: 0.3rem;
  backdrop-filter: blur(8px);
  z-index: 999;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  aspect-ratio: 1/1;
}

.menu-icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
  width: 24px;
  transition: all 0.4s ease-in-out;
}

.line {
  left: 0;
  height: 3px;
  width: 24px;
  position: absolute;
  transition: inherit;
  background-color: #6070ff;
}

.line.open {
  width: 0;
  background-color: white;
}

.line::before,
.line::after {
  left: 0;
  content: '';
  position: absolute;
  height: 3px;
  background-color: inherit;
  transition: inherit;
}

.line.open::before,
.line.open::after {
  transition: inherit;
  width: 24px;
}

.line::before {
  width: 24px;
  transform: translateY(-8px);
}

.line.open::before {
  transform: translateY(0) rotate(135deg);
}

.line::after {
  width: 18px;
  transform: translateY(8px);
}

.line.open::after {
  transform: translateY(0) rotate(-135deg);
}

.nav-links {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 998;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  font-size: 2rem;
  background-color: rgba(96, 112, 255, 0.8);
  backdrop-filter: blur(5px);
  color: white;
  transition: all 0.5s ease-in-out;
  left: -100%;
}

.nav-links.open {
  left: 50%;
}

.nav-item {
  transform: translateX(-100vw);
  display: flex;
  align-items: center;
  justify-items: center;
}

.nav-item > a {
  color: white;
  font-weight: 600;
}

.nav-item.open {
  transform: translateX(0);
}

header {
  background-image: url('./images/header-bg.svg');
  background-repeat: repeat-y;
  background-position-x: center;
  background-size: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 0 0 0 80px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.time {
  color: red;
}

.header-wrapper {
  display: flex;
  gap: 12px;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  width: 100%;
  max-width: 800px;
}

.type-header {
  min-height: 7rem;
  font-family: Poppins-Bold, Roboto, sans-serif;
  font-size: 40px;
}

.header-text,
.about-text {
  font-size: 16px;
  line-height: 24px;
}

.animation-grow {
  transition: all 0.2s ease-in-out;
}

.animation-grow:hover {
  transform: scale(1.1);
}

.animation-grow:focus {
  transform: scale(1.05);
  outline: none;
}

main {
  padding: 114px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 88px;
}

.work-card-container {
  background-color: white;
  border: solid 1px #dfe1e6;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  align-items: stretch;
  box-shadow: 1px 1px 4px -1px #dfe1e6;
  max-width: 800px;
}

.work-tags span {
  background-color: #ebebff;
  padding: 4px 12px;
  border-radius: 8px;
  color: #6070ff;
  font-family: Poppins-Medium, Roboto, sans-serif;
  font-size: 15px;
}

.work-info span {
  font-family: Poppins-SemiBold, Roboto, sans-serif;
  font-size: 13px;
  color: #7a869a;
}

.tag {
  position: absolute;
  right: 2px;
  bottom: 2px;
  background-image: linear-gradient(to right, #fa7199, #e85a19, #fa7199, #e85a19);
  box-shadow: 2px 2px 4px -2px gray;
  padding: 3px 6px;
  font-size: 9px;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  background-size: 300% 100%;
  animation: gradientAnimation 1.5s ease-in-out infinite;
}

:has(.tag) {
  position: relative;
}

.form-field:focus {
  outline: solid 4px rgb(173, 216, 230, 0.6);
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0;
  }

  100% {
    background-position: 100% 0;
  }
}

.link-container {
  display: contents;
}

.last-link {
  overflow: hidden;
}

footer {
  border-top: solid 1px white;
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  padding-left: 2rem;
}

.footer-text {
  color: inherit;
}

.my-logo {
  height: 42px;
}

svg {
  display: block;
}

.contact {
  background-image: url('./images/footer-bg.svg');
  background-color: #6070ff;
  background-position-x: right;
  background-repeat: repeat-y;
  background-size: contain;
  min-height: calc(100vh - 48px);
  flex-direction: column;
  padding: 24px;
  border-radius: 80px 0 0 0;
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-container {
  max-width: 800px;
}

.contact-title {
  color: white;
  text-align: center;
  padding-bottom: 12px;
  font-size: 40px;
  font-family: Poppins-Bold, Roboto, sans-serif;
}

.contact-text {
  color: white;
  text-align: center;
  font-size: 20px;
  padding-bottom: 52px;
}

.header-icons-container {
  display: flex;
  align-items: baseline;
  gap: 20px;
}

#contact_form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
}

input,
textarea {
  flex: 1;
  width: 100%;
  padding: 15px 16px;
  border-radius: 8px;
  border: solid 1px #cfd8dc;
  resize: none;
}

.form-msg {
  padding: 8px 16px;
}

#home,
#works,
#about,
#contact {
  overflow: hidden;
}

.about-container h2 {
  font-family: Poppins-Bold, Roboto, sans-serif;
  font-size: 40px;
  line-height: 52px;
}

.nav-link {
  border: none;
  background: none;
  padding: 8px 16px;
  width: fit-content;
  margin: 0;
  font-family: Poppins-SemiBold, Roboto, sans-serif;
  color: white;
  font-size: 32px;
}

.connect-link {
  background: none;
  border: none;
  width: fit-content;
  color: var(--primary-color);
  font-family: Poppins-Medium, Roboto, sans-serif;
  font-size: 16px;
}

.work-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 6px;
  margin-bottom: 12px;
  background-color: #f2e33933;
}

.work-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.work-name {
  font-size: 32px;
  font-family: Poppins-Bold, Roboto, sans-serif;
}

.work-info {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 0;
  color: blue;
}

.work-info :first-child {
  color: #344563;
}

.work-desc {
  font-family: Poppins-Regular, Roboto, sans-serif;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 12px;
}

.work-note {
  font-family: Poppins-Regular, Roboto, sans-serif;
  color: lightcoral;
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 12px;
}

.work-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 12px;
}

.main-btn,
.form-btn {
  align-self: flex-start;
  background-color: transparent;
  padding: 12px;
  margin: 4px 0;
  border-radius: 8px;
  font-family: Poppins-Medium, Roboto, sans-serif;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  text-transform: uppercase;
}

.main-btn {
  color: #396df2;
  border: solid 1px #6070ff;
  flex: 1;
  justify-content: center;
}

.form-btn {
  color: white;
  border: solid 1px white;
}

.main-btn:hover {
  background-color: #6070ff;
  backdrop-filter: blur(16px);
  color: white;
}

.main-btn:focus {
  background-color: #2230d2;
  color: white;
  outline: none;
}

.main-btn:disabled,
.main-btn.disabled {
  border-color: #c1c7d0;
  color: #5e6c84;
  pointer-events: none;
}

.form-btn:hover {
  background-color: white;
  color: #6070ff;
}

.form-btn:focus {
  background-color: rgba(255, 255, 255, 0.7);
  color: #6070ff;
  outline: none;
}

.download-icon {
  opacity: 0.5;
}

.main-btn:hover > .download-icon {
  transform: translateY(4px);
  animation: moveDown 1s ease-in-out infinite;
  opacity: 0.8;
}

@keyframes moveDown {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(4px);
  }
}

.about-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 0 80px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 114px 24px;
}

.skill-item {
  border-bottom: solid 1px #dfe1e6;
}

.skill-tab {
  display: flex;
  width: 100%;
  background: none;
  border: none;
  justify-content: space-between;
  align-items: center;
  padding: 24px 34px 24px 12px;
  transition: all 0.2s ease-in-out;
}

.skill-tab:hover {
  background: #add8e6;
}

.skill-name {
  font-family: Poppins-Medium, Roboto, sans-serif;
  font-size: 20px;
}

.skill-cards {
  display: flex;
  flex-direction: column;
  margin: 12px;
  gap: 12px;
}

.skill-card {
  background-color: #f7f7f9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 9px 12px;
  gap: 16px;
}

.skill-card img {
  width: 48px;
  height: 48px;
}

.details-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: column;
  padding: 60px 16px;
  align-items: stretch;
  overflow-y: scroll;
}

.details-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-details {
  padding: 8px;
  border: none;
  background: none;
}

.close-details:hover {
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.07);
}

.details-btn {
  flex: 1;
}

.details-btn > a > span {
  color: #396df2;
  font-size: 15px;
  font-family: Poppins-Medium, Roboto, sans-serif;
}

.btn-container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.btn-link {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
}

.none-btn {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}

.ignore-mobile {
  display: contents;
}

.hidden-text {
  display: none;
}

.active-mask {
  display: none;
}

@media screen and (min-width: 768px) {
  .menu-icon {
    display: none;
  }

  .nav-links,
  .nav-links.open {
    top: 1rem;
    width: fit-content;
    left: 50%;
    bottom: auto;
    flex-direction: row;
    background-color: #ebebffd2;
    border-radius: 2rem;
    font-size: 1rem;
    gap: 0;
    box-shadow: 2px 2px 4px -1px #dfe1e6;
  }

  .nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    padding: 8px 16px;
    border-radius: 999rem;
    transform: translateX(0);
    flex: 1;
  }

  .nav-item > a {
    color: #6070ffc1;
  }

  .nav-item > a:hover,
  .nav-item.active > a {
    color: #6070ff;
  }

  .active-mask {
    display: inline-block;
    position: absolute;
    top: 10%;
    height: 80%;
    background-color: rgba(97, 113, 255, 0.1);
    border-radius: 999rem;
    transition: all 0.4s ease-in-out;
  }

  .type-header {
    font-size: 80px;
  }

  .work-img-container {
    margin: 0;
    padding: 0;
    outline: solid 1px #cfd8dc;
    transition: all 0.2s ease-in-out;
    width: fit-content;
  }

  .work-card-container .work-img-container {
    border-top-left-radius: 24px;
    transform: translate(24px, 24px);
    order: 1;
  }

  .work-img-container img {
    border-radius: inherit;
  }

  .work-card-container .work-img-container img {
    object-position: left;
  }

  .work-card-container {
    padding: 0;
    flex-direction: row;
    min-height: 400px;
    overflow: hidden;
  }

  .work-card-container:hover {
    background-color: rgb(249, 249, 255);
  }

  .work-card-container:hover > .work-img-container {
    transform: rotate(-3deg) translate(8px, 40px) scale(1.05);
    box-shadow: -2px 2px 4px #cfd8dc;
  }

  .skill-cards {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .skill-card {
    flex: 1;
  }

  .last-link {
    border-left: solid 2px #cfd8dc;
    border-radius: 0 99px 99px 0;
    width: 100%;
  }

  .link-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contact {
    scroll-margin: -120px;
    padding-top: 200px;
    padding-bottom: 130px;
    border-radius: 160px 0 0 0;
  }

  .form-container {
    padding-bottom: 100px;
  }

  footer {
    justify-content: flex-start;
  }

  header {
    border-radius: 0 0 0 160px;
  }

  /* old style */
  .navbar {
    min-height: 72px;
    padding: 16px 140px;
  }

  .toggle {
    display: none;
  }

  .menu-nav {
    position: static;
    padding: 0;
    flex-direction: row;
    margin: 0;
    background: none;
  }

  .nav-link {
    color: #344563;
    padding: 10px 12px;
    font-size: 15px;
    font-family: Poppins-Medium, Roboto, sans-serif;
  }

  .menu-nav.show-menu {
    height: auto;
    box-shadow: none;
    gap: 8px;
    justify-content: right;
  }

  main {
    display: flex;
    flex-direction: column;
    gap: 140px;
  }

  .details-container .work-card-container {
    flex-direction: column;
  }

  .work-card-container > * {
    flex: 1;
  }

  .ignore-mobile {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .work-info {
    padding: 10px 0;
  }

  .work-desc {
    flex-grow: 1;
  }

  .details-body {
    display: flex;
    gap: 24px;
  }

  .details-tags-btns {
    flex: 1;
  }

  .details-container .work-tags {
    border-bottom: solid 1px #ebecf0;
  }

  .about-container {
    padding: 166px 140px;
    border-radius: 0 160px;
  }

  .about-container > .ignore-mobile {
    gap: 12px;
  }

  .about-container > * {
    flex: 1;
  }

  .skills-container {
    flex: 1;
    height: 100%;
  }

  .menu-nav > li:hover {
    background-color: rgba(0, 0, 0, 0.07);
    border-radius: 8px;
  }

  .menu-nav > li:active {
    background-color: rgba(0, 0, 255, 0.07);
    border-radius: 8px;
  }
}

@media screen and (min-width: 1024px) {
  .about-container {
    flex-direction: row;
  }
}
